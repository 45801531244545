import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { getSocialMediaData } from "../../redux/actions/globalAction";
import {
  mediaUrlConstants,
  languages,
  ConfigurationKeys,
} from "../../utilities/constants/global.constants";
import { useSelector, useDispatch } from "react-redux";
import {
  // AmericanExpressIcon,
  ApplePayIcon,
  CasmadaIcon,
  InstagramIcon,
  LinkedInIcon,
  MasterCardIcon,
  STCPayIcon,
  TwitterIcon,
  VisaCardIcon,
} from "../gogo-icons";
import { FacebookIcon } from "../gogo-icons/FacebookIcon";
import { YoutubeIcon } from "../gogo-icons/YoutubeIcon";
import { ApplicationRoles } from "../../utilities/constants/role.constants";
import { getCareersEmailId } from "../../redux/actions/masterDataConfigurationAction";

function Footer() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSocialMediaData());
  }, [dispatch]);
  const userRoles = useSelector((state) => state.authReducer.userRoles);

  const socialMediaData = useSelector(
    (state) => state.globalReducer.socialMediaData
  );

  const selectedLanguage = useSelector(
    (state) => state.globalReducer.selectedLanguage
  );

  const careersEmailIdDetails = useSelector(
    (state) => state.masterDataConfigurationReducer.careersEmailId
  );

  useEffect(() => {
    const careersEmailIdRequest = {
      configurationKey: ConfigurationKeys.CareersEmailId,
      languageId: selectedLanguage,
    };
    dispatch(getCareersEmailId(careersEmailIdRequest));
  }, [dispatch, selectedLanguage]);

  const getSocialMediaURL = (mediaType) => {
    if (
      socialMediaData &&
      socialMediaData.SocialMedia &&
      socialMediaData.SocialMedia.length > 0
    ) {
      const media = socialMediaData.SocialMedia.find(
        (itm) => itm.SocialMediaType.trim() === mediaType
      );
      return media ? media.MediaUrl : "";
    }
    return "";
  };

  return (
    <>
      <footer
        id="footer"
        className={`footer ${
          selectedLanguage === languages.ar && "ar-footer-direction"
        }`}
      >
        <div className="footer-container py-10 gap-10 flex flex-col text-white xl:text-base text-sm">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 internal-gap">
            <div className="flex flex-col internal-gap items-center md:items-start">
              <div>{t("ABOUT_GOGO_MOTOR")}</div>
              <div className="w-full grid sm:grid-cols-3 md:grid-cols-2">
                <div className="flex justify-center md:justify-start">
                  <Link to="/about-us">{t("ABOUT_US")}</Link>
                </div>
                <div className="flex justify-center md:justify-start">
                  <a
                    href={`mailto:${careersEmailIdDetails.ConfigurationValue}`}
                  >
                    {t("CAREERS")}
                  </a>
                </div>
                <div className="flex justify-center md:justify-start">
                  <Link to="/privacy-policy">{t("PRIVACY_POLICY")}</Link>
                </div>
                {(userRoles[0] === ApplicationRoles.DMS_ADMIN ||
                  userRoles[0] === ApplicationRoles.DMS_SALESPERSON) && (
                  <div className="flex justify-center md:justify-start">
                    <Link to="/user-feedback">{t("CONTACT_US")}</Link>
                  </div>
                )}
                <div className="flex justify-center md:justify-start">
                  <Link to="/terms-conditions">{t("TERMS_CONDITIONS")}</Link>
                </div>
              </div>
            </div>
            <div className="flex flex-col internal-gap items-center md:items-start">
              <div className="text-center sm:text-left">
                {t("KEEP_IN_TOUCH")}
              </div>
              <div className="flex flex-row gap-10">
                <div className="flex gap-5 items-center justify-center">
                  <a
                    href={getSocialMediaURL(mediaUrlConstants.Facebook)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FacebookIcon className="w-10" />
                  </a>
                  <a
                    href={getSocialMediaURL(mediaUrlConstants.YouTube)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <YoutubeIcon className="w-10" />
                  </a>
                  <a
                    href={getSocialMediaURL(mediaUrlConstants.Instagram)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <InstagramIcon className="w-10" />
                  </a>
                  <a
                    href={getSocialMediaURL(mediaUrlConstants.twitter)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <TwitterIcon className="w-10" />
                  </a>
                  <a
                    href={getSocialMediaURL(mediaUrlConstants.LinkedIn)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <LinkedInIcon className="w-10" />
                  </a>
                </div>
              </div>
            </div>
            <div className="flex lg:justify-end justify-center items-center self-end gap-5 lg:col-span-2">
              <CasmadaIcon className="payment-icon" />
              <MasterCardIcon className="payment-icon" />
              <VisaCardIcon className="payment-icon" />
              {/* <AmericanExpressIcon className="payment-icon" /> */}
              <ApplePayIcon className="payment-icon" />
              <STCPayIcon className="payment-icon" />
            </div>
          </div>
          <hr className="border-white" />
          <div className="flex justify-center sm:justify-start">
            <span>{t("COPYRIGHT_GOGOMOTOR")}</span>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
