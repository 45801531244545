export class CdnHelper {
  static getOciCdnSrc = (src, width, quality = 100) => {
    if (src.startsWith("https://") || src.startsWith("http://")) {
      return src;
    }
    if (src.startsWith("data:image/")) {
      return src;
    }
    if (!width) {
      return `${process.env.REACT_APP_OCI_CDN_URL}/${process.env.REACT_APP_OCI_CDN_ASSETS_PATH}${src}`;
    }

    return `${process.env.REACT_APP_OCI_CDN_URL}/cdn-cgi/image/width=${width},quality=${quality}/${process.env.REACT_APP_OCI_CDN_ASSETS_PATH}${src}`;
  };

  static getAzureCdnSrc = (src) => {
    if (src.startsWith("https://") || src.startsWith("http://")) {
      return src;
    }
    if (src.startsWith("data:image/")) {
      return src;
    }
    return `${process.env.REACT_APP_IMAGE_CDN}${src}`;
  };
}
