import React, { useState } from "react";
import { CdnHelper } from "../../utilities/helpers/cdn.helper";
import defaultCar from "../../assets/images/default-car.jpg";

const ImageWithFallback = ({
  src,
  fallbackSrc = defaultCar,
  alt,
  className,
  width = undefined,
  height = undefined,
  ...rest
}) => {
  const [isFirstError, setIsFirstError] = useState(true);
  if (src.includes("profile-view-1-1727793996.jpeg")) {
    console.log({
      oci: CdnHelper.getOciCdnSrc(src, width),
      az: CdnHelper.getAzureCdnSrc(src),
    });
  }
  return (
    <img
      src={CdnHelper.getOciCdnSrc(src, width)}
      width={width}
      height={height}
      alt={alt}
      crossOrigin="anonymous"
      onError={(e) => {
        console.log("Error loading image", src);
        if (isFirstError) {
          e.target.src = CdnHelper.getAzureCdnSrc(src);
          e.onerror = null;
          setIsFirstError(false);
        } else {
          e.target.src = fallbackSrc;
          e.onerror = null;
        }
      }}
      className={className}
      {...rest}
    />
  );
};

export default ImageWithFallback;
