import React, { useCallback, useEffect, useState } from "react";
import { CloseIcon, DownloadRedIcon } from "../../gogo-icons";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { MimeTypes } from "../../../utilities/constants/global.constants";
import { getFileData } from "../../../redux/actions/vehicleAction";
import ImageWithFallback from "../ImageWithFallback";

const DocumentPreview = ({
  showPopUp,
  documentUrl,
  closeModal,
  mimeType,
  documentName,
  isVideoIframePreview,
  openBuyerDocModal,
}) => {
  const [documentType, setDocumentType] = useState({
    isImage: false,
    isVideo: false,
    isDoc: false,
  });
  const [url, setUrl] = useState(null);

  const downloadDocument = useCallback(
    (close = false) => {
      if (documentUrl) {
        const a = document.createElement("a");
        a.href = documentUrl;
        documentName && a.setAttribute("download", documentName);
        a.click();
      }
      if (close) {
        closeModal(false);
      }
    },
    [documentUrl, documentName, closeModal]
  );

  useEffect(() => {
    if (showPopUp) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "unset";
    return function () {
      document.body.style.overflow = "unset";
    };
  }, [showPopUp]);

  useEffect(() => {
    const prepareData = async () => {
      const type = {
        isImage: false,
        isVideo: false,
        isDoc: false,
      };
      if (
        isVideoIframePreview &&
        [MimeTypes.VideoMP4].includes(mimeType.toLowerCase())
      ) {
        setDocumentType({ ...type, isVideo: true });
        setUrl(documentUrl);
      } else if (
        [
          MimeTypes.ImageJPEG,
          MimeTypes.ImageJPG,
          MimeTypes.ImagePNG,
          MimeTypes.ImageBMP,
          MimeTypes.ImageWebP,
        ].includes(mimeType.toLowerCase())
      ) {
        setDocumentType({ ...type, isImage: true });
        setUrl(documentUrl);
      } else if ([MimeTypes.VideoMP4].includes(mimeType.toLowerCase())) {
        setDocumentType({ ...type, isVideo: true });
        setUrl(documentUrl);
      } else if ([MimeTypes.ApplicationPDF].includes(mimeType.toLowerCase())) {
        if (isValidUrl(documentUrl)) {
          const urlObject = await blobToUrl(documentUrl, mimeType);
          setUrl(urlObject);
        } else {
          setUrl(documentUrl);
        }
        setDocumentType({ ...type, isDoc: true });
      } else {
        downloadDocument(true);
      }
    };
    if (documentUrl && mimeType) {
      prepareData();
    }
  }, [mimeType, documentUrl, isVideoIframePreview, downloadDocument]);

  function isValidUrl(str) {
    const pattern = new RegExp(
      "^([a-zA-Z]+:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR IP (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$", // fragment locator
      "i"
    );
    return pattern.test(str);
  }

  const blobToUrl = async (url, mimeType) => {
    const blob = await getFileData(url);
    const newBlob = new Blob([blob], { type: mimeType });
    return URL.createObjectURL(newBlob);
  };

  return (
    <>
      {showPopUp &&
        (documentType.isDoc ||
          documentType.isImage ||
          documentType.isVideo) && (
          <div className="fixed z-30 top-0 left-0 h-full w-full bg-gray-800 bg-opacity-80">
            <div className="w-full h-full top-0 left-0 flex flex-col justify-center items-center">
              <div className="h-full w-full flex justify-center items-center opacity-100">
                <div className="image-pop-up w-full relative flex flex-col-reverse lg:flex-row md:justify-center gap-5">
                  {documentType.isImage && url && (
                    <div className="w-full">
                      <TransformWrapper>
                        <TransformComponent
                          wrapperClass="transform-wrapper aspect-[16/9]"
                          contentClass="transform-wrapper"
                        >
                          <picture className="w-full aspect-video">
                            <ImageWithFallback
                              src={url}
                              alt="used-cars"
                              className="w-full aspect-video object-contain"
                            />
                          </picture>
                        </TransformComponent>
                      </TransformWrapper>
                    </div>
                  )}
                  {!isVideoIframePreview && documentType.isVideo && url && (
                    <div className="w-full">
                      <video className="w-full" controls>
                        <source src={url} type={mimeType} />
                        Your browser does not support HTML video.
                      </video>
                    </div>
                  )}
                  {documentType.isVideo && url && isVideoIframePreview && (
                    <iframe className="w-full h-full" src={url} />
                  )}
                  {documentType.isDoc && url && (
                    <iframe className="w-full h-full" src={url} />
                  )}
                  <div className="flex flex-col gap-6">
                    <div
                      onClick={() => {
                        closeModal(false);
                        openBuyerDocModal && openBuyerDocModal(true);
                      }}
                    >
                      <CloseIcon className="cursor-pointer w-10 h-10 text-white hover:bg-gray-500 p-2 bg-opacity-5" />
                    </div>
                    {!documentType.isDoc && !isVideoIframePreview && (
                      <div
                        onClick={() => {
                          downloadDocument();
                        }}
                      >
                        <DownloadRedIcon className="cursor-pointer w-10 h-10 text-white hover:bg-gray-500 p-2 bg-opacity-5" />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
    </>
  );
};

export default DocumentPreview;
