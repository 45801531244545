import axios from "axios";
import { store } from "../src/redux/store";
import { ConfigConstants } from "./utilities/constants/global.constants";
import {
  getAuthTokenFromRefreshToken,
  isTokenValid,
} from "./redux/actions/authAction";
import MessageBox, { MessageBoxType } from "./components/common/message-box";
import i18next from "i18next";
import { FETCH_AUTH_TOKEN_ERROR, FETCH_AUTH_TOKEN_FAIL } from "./redux/types";
import { AuthConstants } from "./utilities/constants/auth.constants";

let isRefreshing = false;
let subscribers = [];

function onRefreshed({ authorizationToken }) {
  subscribers.map((cb) => cb(authorizationToken));
}

function subscribeTokenRefresh(cb) {
  subscribers.push(cb);
}

export const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const API2 = axios.create({
  baseURL: process.env.REACT_APP_CS_API_URL,
});

API.interceptors.request.use(
  (request) => {
    const { allowAnonymous, ...req } = request;
    const state = store.getState();
    const token = state && state.authReducer && state.authReducer.token;
    const isAuthentic =
      state && state.authReducer && state.authReducer.isAuthentic;

    if (isAuthentic === true && !allowAnonymous) {
      if (isTokenValid(token)) {
        req.headers.Authorization = token ? `Bearer ${token}` : "";
      }

      // else {
      //   (error) => {
      //     Promise.reject(error.code === 401);
      //   };
      // }
    }
    return req;
  },
  (error) => {
    Promise.reject(error);
  }
);

API.interceptors.response.use(
  (response) => {
    successResponse(response);
    return response;
  },
  (error) => {
    errorResponse(error);
    let errorMessage = "";
    if (error.message == ConfigConstants.NetworkError) {
      errorMessage = ConfigConstants.NetworkError;
      console.error(errorMessage);
    }

    if (error && error.response && error.response.status === 500) {
      errorMessage =
        ConfigConstants.refreshError +
        ConfigConstants.ErrorDetails +
        error.response.statusText;
      console.error(errorMessage);
    }
    if (error && error.response && error.response.status === 404) {
      errorMessage =
        ConfigConstants.serverError +
        error.response.config.url +
        ConfigConstants.ErrorDetails +
        error.response.statusText;
      console.error(errorMessage);
    }

    const originalRequest = error.config;
    //this block will check if the request was from token api
    if (
      String(error.request.responseURL)
        .toLowerCase()
        .indexOf("api/webauth/token") > -1 &&
      error &&
      error.response &&
      error.response.status === 401
    ) {
      MessageBox.open({
        content: i18next.t("FRM_ERR_MSG_SOMETHING_WRONG"),
        type: MessageBoxType.Alert,
      }).then(() => {
        store.dispatch({ type: FETCH_AUTH_TOKEN_ERROR });
        store.dispatch({ type: FETCH_AUTH_TOKEN_FAIL });
      });
    } else if (error && error.response && error.response.status === 401) {
      if (!isRefreshing) {
        isRefreshing = true;
        store.dispatch(getAuthTokenFromRefreshToken()).then((newTokens) => {
          if (newTokens) {
            isRefreshing = false;
            onRefreshed(newTokens && newTokens.data && newTokens.data.Token);
            subscribers = [];
          }
        });
      }
      return new Promise((resolve) => {
        subscribeTokenRefresh(() => {
          const state = store.getState();
          const token = state && state.authReducer && state.authReducer.token;
          originalRequest.headers.Authorization = `Bearer ${token}`;
          resolve(axios(originalRequest));
        });
      });
    }
    return Promise.reject(error);
  }
);

API2.interceptors.request.use(
  (request) => {
    const { allowAnonymous, ...req } = request;
    const state = store.getState();
    const token = state && state.authReducer && state.authReducer.token;
    const isAuthentic =
      state && state.authReducer && state.authReducer.isAuthentic;

    if (isAuthentic === true && !allowAnonymous) {
      if (isTokenValid(token)) {
        req.headers.Authorization = token ? `Bearer ${token}` : "";
      }

      // else {
      //   (error) => {
      //     Promise.reject(error.code === 401);
      //   };
      // }
    }
    return req;
  },
  (error) => {
    Promise.reject(error);
  }
);

API2.interceptors.response.use(
  (response) => {
    successResponse(response);
    return response;
  },
  (error) => {
    errorResponse(error);
    let errorMessage = "";
    if (error.message == ConfigConstants.NetworkError) {
      errorMessage = ConfigConstants.NetworkError;
      console.error(errorMessage);
    }

    if (error && error.response && error.response.status === 500) {
      errorMessage =
        ConfigConstants.refreshError +
        ConfigConstants.ErrorDetails +
        error.response.statusText;
      console.error(errorMessage);
    }
    if (error && error.response && error.response.status === 404) {
      errorMessage =
        ConfigConstants.serverError +
        error.response.config.url +
        ConfigConstants.ErrorDetails +
        error.response.statusText;
      console.error(errorMessage);
    }

    const originalRequest = error.config;
    //this block will check if the request was from token api
    if (
      String(error.request.responseURL)
        .toLowerCase()
        .indexOf("api/webauth/token") > -1 &&
      error &&
      error.response &&
      error.response.status === 401
    ) {
      MessageBox.open({
        content: i18next.t("FRM_ERR_MSG_SOMETHING_WRONG"),
        type: MessageBoxType.Alert,
      }).then(() => {
        store.dispatch({ type: FETCH_AUTH_TOKEN_ERROR });
        store.dispatch({ type: FETCH_AUTH_TOKEN_FAIL });
      });
    } else if (error && error.response && error.response.status === 401) {
      if (!isRefreshing) {
        isRefreshing = true;
        store.dispatch(getAuthTokenFromRefreshToken()).then((newTokens) => {
          if (newTokens) {
            isRefreshing = false;
            onRefreshed(newTokens && newTokens.data && newTokens.data.Token);
            subscribers = [];
          }
        });
      }
      return new Promise((resolve) => {
        subscribeTokenRefresh(() => {
          const state = store.getState();
          const token = state && state.authReducer && state.authReducer.token;
          originalRequest.headers.Authorization = `Bearer ${token}`;
          resolve(axios(originalRequest));
        });
      });
    }
    return Promise.reject(error);
  }
);

const successResponse = (response) => {
  if (process.env.REACT_APP_IS_LOG_VAULT === "true") {
    createGlobalLogs({
      url:
        `${
          response?.config?.baseURL
            ? `${response?.config?.baseURL}${response?.config?.url}`
            : `${typeof window !== "undefined" ? window.location.origin : ""}${
                response?.config?.url
              }`
        }` || "",
      http_method: response?.config?.method || "",
      request_body:
        (response?.config?.data && JSON?.parse(response?.config?.data)) || {},
      status_code: response?.status || "",
      headers: response?.config?.headers || {},
    });
  }
};

const errorResponse = (error) => {
  if (process.env.REACT_APP_IS_LOG_VAULT === "true") {
    createGlobalLogs({
      url:
        `${
          error?.config?.baseURL
            ? `${error?.config?.baseURL}${error?.config?.url}`
            : `${typeof window !== "undefined" ? window.location.origin : ""}${
                error?.config?.url
              }`
        }` || "",
      http_method: error?.config?.method || "",
      request_body:
        (error?.config?.data && {
          data: JSON?.stringify(error?.config?.data),
        }) ||
        {},
      status_code: error?.response?.status || "",
      response_body:
        (error?.response?.data && {
          data: JSON.stringify(error?.response?.data),
        }) ||
        {},
      headers: error?.config?.headers || {},
    });
  }
};
const createGlobalLogs = async (data) => {
  const userStringData = localStorage.getItem(AuthConstants.UserDetails);
  const userData = JSON.parse(userStringData);
  const payload = {
    client_ip: "0.0.0.0",
    clearity_id: userData?.UserId,
    ...data,
  };
  process.env["NODE_TLS_REJECT_UNAUTHORIZED"] = "0";
  await axios.post(
    process.env.REACT_APP_LOG_VAULT_API_BASE_URL +
      `/opensearch?index=${process.env.REACT_APP_DOMAIN}`,
    payload,
    {
      headers: {
        Accept: "application.json",
        "Content-Type": "application/json",
      },
    }
  );
};
